import { useCartStore } from "~/stores/CartStore"
import { useNuxtApp } from "#app"
import { storeToRefs } from "pinia"
import { usePaysafeStore } from "~/stores/PaysafeStore"
import { useCurrencyStore } from "~/stores/CurrencyStore"
import { useProductStore } from "~/stores/ProductStore"
import { useOrderStore } from "~/stores/OrderStore"
import { FLUSH_CART_STORE, SHOW_ERROR } from "assets/events/errors.js"
import { QTY_ADDED_TO_CART } from "assets/events/api.js"
import CartItem from "~/models/CartItem"
import { CART_CLEARED, MODIFIED_CART_CURRENCY, MODIFIED_RESERVED_ITEM } from "assets/events/cart.js"
import confetti from "canvas-confetti"

export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()
	const gtm = useGtm()
	const lodgingProductTypeId = runtimeConfig.public.PRODUCTS.lodging.productTypeId
	const addonProductTypeId = runtimeConfig.public.PRODUCTS.addon.productTypeId
	const seatsIoEnabled = !!runtimeConfig.public.SEATS_IO

	const cartStore = useCartStore()
	const { cartId } = storeToRefs(cartStore)
	const { $apiHelper, $cartApiService, $cartTimeout, $eventBus, $messages, $countryAndStatesService, $currency } =
		useNuxtApp()

	const currencyStore = useCurrencyStore()
	const { currency } = storeToRefs(currencyStore)
	const paysafeStore = usePaysafeStore()
	const productStore = useProductStore()
	const orderStore = useOrderStore()

	let isPlacingOrder = false

	const callGetCart = async () => {
		$apiHelper.$showLoader()
		const { data, pending, status } = await $cartApiService.$getCart(cartId.value)

		$apiHelper.$handleResponse(pending, status, data, (response) => {
			const cartData = response.cart
			handleGetCartResponse(cartData)
		})
	}

	const getCartMetaRowFromResponse = (response) => {
		if (response && Array.isArray(response)) {
			response = response.filter((item) => item.sk === "meta")
			return response.length ? response[0] : []
		} else {
			throw createError({ statusCode: 500, statusMessage: "Got an invalid response from API" })
		}
	}

	//Parse out the cartId from the response, AWS's pk pattern forces the use of hardcoded string split here
	const storeCartId = (response) => {
		const cartPk = getCartMetaRowFromResponse(response).pk
		const splitCartPk = cartPk.split("#")
		const cartId = splitCartPk[1]
		cartStore.setCartId(cartId)
		return cartId
	}

	//Get the Cart expiry from the response
	const storeCartExpiry = (response) => {
		const expiresAtEpoch = getCartMetaRowFromResponse(response).expiresAt
		setCartExpiry(expiresAtEpoch)
	}

	//Utilize the watcher in CartTimeoutService
	const setCartExpiry = (expiresAtEpoch) => {
		let expirationDate = new Date(0)
		// expiresAtEpoch -= 570 //FOR TESTING: Set to 6 minutes
		expirationDate.setUTCSeconds(expiresAtEpoch)
		cartStore.setExpiresAt(expirationDate)
	}

	//Gets all cart meta data except cartId and expiresAt
	const storeCartMeta = (response) => {
		const cartMetaRow = getCartMetaRowFromResponse(response)
		const subtotal = cartMetaRow.subtotal
		const total = cartMetaRow.total
		const fees = cartMetaRow.fees
		const refundableBookingCost = cartMetaRow.refundableBookingCost
		const isRefundableBookingSelected = cartMetaRow.isRefundableBookingSelected
		const installments = Object.values(cartMetaRow.installments)
		const appliedPromocode = cartMetaRow.appliedPromocode
		const discountedAmount = cartMetaRow.discountedAmount
		const exchangeRate = cartMetaRow.exchangeRate
		const currency = cartMetaRow.currency

		cartStore.setSubTotal(subtotal)
		cartStore.setTotal(total)
		cartStore.setFees(fees)
		cartStore.setRefundableBookingCost(refundableBookingCost)
		cartStore.setIsRefundableBookingSelected(isRefundableBookingSelected)
		cartStore.setInstallments(installments)
		cartStore.setAppliedPromocode(appliedPromocode)
		cartStore.setDiscountedAmount(discountedAmount)
		cartStore.setExchangeRate(exchangeRate)
		cartStore.setCurrency(currency)
	}

	const updateCurrencyAndBillingInfo = (currencyCode) => {
		const currencyObj = $currency.$getCurrencyByCode(currencyCode)
		currencyStore.switchCurrency(currencyObj)

		if (cartStore.billingCountry !== currencyObj.countryIso3) {
			cartStore.setBillingCountry(currencyObj.countryIso3)
			cartStore.setBillingState("")
		}
	}

	//Get and store all the cart items from the response
	const storeCartItems = (response) => {
		const cartItems = getCartItemsFromResponse(response)
		//CartItems can be 0 if the user updates the quantity to 0 using the counter buttons
		if (cartItems.length <= 0) {
			cartStore.resetCartMetaAndItems()
		} else {
			cartStore.resetCartItems()
			cartItems.forEach((item) => {
				const splitProductSk = item.sk.split("#")
				item.id = splitProductSk[1]
				const cartItem = CartItem.createFromPartial(item)
				cartStore.addItem(cartItem)
			})
		}
	}

	const getCartItemsFromResponse = (response) => {
		if (response && Array.isArray(response)) {
			return response.filter((item) => item.sk !== "meta")
		} else {
			throw createError({ statusCode: 500, statusMessage: "Got an invalid response from API" })
		}
	}

	const handleGetCartResponse = (cartData) => {
		storeCartExpiry(cartData)
		storeCartMeta(cartData)
		storeCartItems(cartData)
	}

	const handleAddCartItemResponse = (cartData) => {
		storeCartId(cartData)
		storeCartExpiry(cartData)
		storeCartMeta(cartData)
		storeCartItems(cartData)
	}

	const handleUpdateCartItemResponse = (cartData) => {
		if (clearCartIfEmpty(cartData)) {
			return
		}

		storeCartMeta(cartData)
		storeCartItems(cartData)
	}

	const handleDeleteCartItemResponse = (productId, cartData) => {
		const removedItem = cartStore.removeItem(productId)

		if (clearCartIfEmpty(cartData)) {
			return
		}

		storeCartMeta(cartData)
		storeCartItems(cartData)
	}

	const clearCartIfEmpty = (response) => {
		if (response && Array.isArray(response) && response.length === 0) {
			cartStore.resetCartMetaAndItems()
			return true
		}
		return false
	}

	const handlePlaceOrderResponse = (response) => {
		const cartData = response.cart
		const cartMetaRow = getCartMetaRowFromResponse(cartData)
		trackOrderForAnalytics(cartMetaRow)
		orderStore.$reset()

		orderStore.setOrderId(cartMetaRow.orderId)
		orderStore.setFirstName(cartStore.billingFirstName)
		orderStore.setLastName(cartStore.billingLastName)
		orderStore.setEmail(cartStore.shippingEmail)
		orderStore.setAddress1(cartStore.billingAddress1)
		orderStore.setAddress2(cartStore.billingAddress2)
		orderStore.setCountry(cartStore.billingCountry)
		orderStore.setState(cartStore.billingState)
		orderStore.setCity(cartStore.billingCity)
		orderStore.setZipCode(cartStore.billingZipcode)

		orderStore.setSubTotal(cartMetaRow.subtotal)
		orderStore.setTotal(cartMetaRow.total)
		orderStore.setFees(cartMetaRow.fees)
		orderStore.setRefundableBookingCost(cartMetaRow.refundableBookingCost)
		orderStore.setDiscountedAmount(cartMetaRow.discountedAmount)
		orderStore.setInstallments(Object.values(cartMetaRow.installments))

		const cartItems = getCartItemsFromResponse(cartData)
		cartItems.forEach((item) => {
			const splitProductSk = item.sk.split("#")
			item.id = splitProductSk[1]
			const cartItem = CartItem.createFromPartial(item)
			orderStore.addItem(cartItem)
		})
	}

	const trackOrderForAnalytics = (cartMetaRow) => {
		if (gtm?.options?.enabled) {
			gtm?.dataLayer().push({ transaction_id: cartMetaRow.orderId })
			gtm?.dataLayer().push({ total_amount: cartMetaRow.total })
		}
	}

	const handleExtendCartTimeoutResponse = (response) => {
		//Reset the cart timeout interrupts
		const newCartExpiryEpoch = response.expiresAt
		setCartExpiry(newCartExpiryEpoch)
	}

	const resetCartDataToDefaults = async () => {
		cartStore.$reset()
		$eventBus.$emit(CART_CLEARED)
	}

	const isCartEmpty = () => {
		if (!cartId.value) {
			$eventBus.$emit(SHOW_ERROR, {
				titleText: $messages.$t("noCartExists"),
				bodyText: $messages.$t("noCartItemsBody"),
				clickOutsideEnabled: true,
			})
			return true
		}
		return false
	}

	const showSeatSelector = (productId, currentQuantity) => {
		if (!seatsIoEnabled) {
			return false
		}

		if (currentQuantity <= 0) {
			return false
		}

		const modifiedItem = cartStore.items.get(productId)
		if (modifiedItem.reservationSection) {
			$eventBus.$emit(MODIFIED_RESERVED_ITEM, { productId: productId })
			return true
		} else {
			return false
		}
	}

	nuxtApp.provide("cartService", {
		$init: () => {
			$eventBus.$on(FLUSH_CART_STORE, resetCartDataToDefaults)
		},

		$getCart: callGetCart,

		//Called whenever an item quantity is changed
		//Intended to be used on a product page (Card.vue) where the counters do not show real-time item quantities
		$addCartItemQty: async (id, quantityAdded) => {
			const itemInCart = cartStore.getItem(id)
			const totalQuantity = itemInCart ? itemInCart.quantity + quantityAdded : quantityAdded

			// prevent user from adding too many products
			const productInStore = productStore.getProductById(id)
			if (productInStore && totalQuantity > productInStore.availableQuantity) {
				$apiHelper.$hideLoader()
				$eventBus.$emit(SHOW_ERROR, {
					titleText: $messages.$t("maxReachedTitle"),
					bodyText: $messages.$t("maxReachedBody", { max: productInStore.availableQuantity }),
				})
				return
			}

			$apiHelper.$showLoader()

			const { data, pending, status } = await $cartApiService.$setCartItemQty(
				cartId.value,
				id,
				currency.value.code,
				totalQuantity,
				productInStore.price[currency.value.code]
			)

			$apiHelper.$handleResponse(pending, status, data, (response) => {
				const cartData = response.cart
				handleAddCartItemResponse(cartData)
				$eventBus.$emit(QTY_ADDED_TO_CART, { productId: id })
				if (!showSeatSelector(id, totalQuantity)) {
					confetti()
				}
			})
		},

		//Called whenever an item quantity is changed
		$setCartItemQty: async (productId, quantity) => {
			if (isCartEmpty()) {
				return
			}

			const itemInCart = cartStore.getItem(productId)
			if (itemInCart.quantity === quantity) {
				//The Cart item quantity did not change
				return
			}

			// prevent user from adding too many products
			const productInStore = productStore.getProductById(productId)
			const availableQty = productInStore.availableQuantity
			if (quantity > availableQty) {
				$eventBus.$emit(SHOW_ERROR, {
					titleText: $messages.$t("maxReachedTitle"),
					bodyText: $messages.$t("maxReachedBody", { max: availableQty }),
				})
				return
			}

			$apiHelper.$showLoader()

			const { data, pending, status } = await $cartApiService.$setCartItemQty(
				cartId.value,
				productId,
				currency.value.code,
				quantity,
				productInStore.price[currency.value.code]
			)

			$apiHelper.$handleResponse(pending, status, data, (response) => {
				const cartData = response.cart
				handleUpdateCartItemResponse(cartData)

				if (!showSeatSelector(productId, quantity)) {
					confetti()
				}
			})
		},

		//Called whenever an item removed from the cart
		$deleteCartItem: async (productId) => {
			if (isCartEmpty()) {
				return
			}

			$apiHelper.$showLoader()

			const { data, pending, status } = await $cartApiService.$deleteCartItem(cartId.value, productId)

			$apiHelper.$handleResponse(pending, status, data, (response) => {
				const cartData = response.cart
				handleDeleteCartItemResponse(productId, cartData)
			})
		},

		$updateInstallments: async (numInstallments) => {
			//The user could go to the checkout page when the cart is empty
			if (!cartStore.cartId) {
				return
			}

			$apiHelper.$showLoader()
			const { data, pending, status } = await $cartApiService.$updateInstallments(cartId.value, numInstallments)

			$apiHelper.$handleResponse(pending, status, data, (response) => {
				const cartData = response.cart
				//installments are in the cart meta
				storeCartMeta(cartData)
				//Installment fees may have been added
				storeCartItems(cartData)
			})
		},

		$placeOrder: async (successHandler) => {
			if (isCartEmpty()) {
				return
			}

			if (!isPlacingOrder) {
				isPlacingOrder = true
				//Only allow orders to be placed once per second
				setTimeout(() => {
					isPlacingOrder = false
				}, 1000)
				$apiHelper.$showLoader()

				const cartBody = {
					total: cartStore.total,
					token: paysafeStore.token,
					reservationHoldToken: cartStore.reservationHoldToken,
					user: {
						firstName: cartStore.shippingFirstName,
						lastName: cartStore.shippingLastName,
						billingFirstName: cartStore.billingFirstName,
						billingLastName: cartStore.billingLastName,
						email: cartStore.shippingEmail,
						phone: cartStore.shippingPhone,
						address: cartStore.billingAddress1,
						address2: cartStore.billingAddress2,
						city: cartStore.billingCity,
						state: cartStore.billingState,
						country: $countryAndStatesService.$convertIso3ToIso2(cartStore.billingCountry),
						zip: cartStore.billingZipcode,
					},
				}

				const { data, pending, status } = await $cartApiService.$placeOrder(cartId.value, cartBody)

				$apiHelper.$handleResponse(pending, status, data, (placeOrderRes) => {
					handlePlaceOrderResponse(placeOrderRes)
					if (successHandler && typeof successHandler === "function") {
						successHandler()
					}
				})
			}
		},

		$extendCartTimeout: async () => {
			if (isCartEmpty()) {
				return
			}

			$apiHelper.$showLoader()
			const { data, pending, status } = await $cartApiService.$extendCartTimeout(cartId.value)

			$apiHelper.$handleResponse(pending, status, data, (extendCartRes) => {
				handleExtendCartTimeoutResponse(extendCartRes)
			})
		},

		$updateRefundableBooking: async (isRefundableBookingSelected) => {
			if (!cartId.value) {
				return
			}

			$apiHelper.$showLoader()
			const { data, pending, status } = await $cartApiService.$updateRefundableBooking(
				cartId.value,
				isRefundableBookingSelected
			)

			$apiHelper.$handleResponse(pending, status, data, (response) => {
				cartStore.setIsRefundableBookingSelected(isRefundableBookingSelected)
				const cartData = response.cart
				storeCartMeta(cartData)
			})

			//Revert the refundable booking selection if the API call failed
			if (!pending.value && status.value === "error") {
				cartStore.setIsRefundableBookingSelected(!isRefundableBookingSelected)
			}
		},

		$setPromoCode: async (promoCode) => {
			if (isCartEmpty()) {
				return
			}

			$apiHelper.$showLoader()
			const { data, pending, status } = await $cartApiService.$setPromoCode(
				cartId.value,
				promoCode,
				cartStore.billingFirstName,
				cartStore.billingLastName,
				cartStore.shippingEmail
			)

			$apiHelper.$handleResponse(pending, status, data, (response) => {
				const cartData = response.cart
				storeCartMeta(cartData)
				//Installment fees may have been changed
				storeCartItems(cartData)
				confetti()
			})
		},

		//Make sure to switch the currencyStore AFTER calling this method, otherwise no change will be detected
		$updateCartCurrency: async (currencyCode) => {
			if (!cartId.value) {
				//If there is no cart, the user can freely switch currency
				updateCurrencyAndBillingInfo(currencyCode)
				return
			}

			if (currencyCode !== currency.value.code) {
				$apiHelper.$showLoader()
				const { data, pending, status } = await $cartApiService.$updateCartCurrency(cartId.value, currencyCode)

				$apiHelper.$handleResponse(pending, status, data, (response) => {
					const cartData = response.cart
					storeCartMeta(cartData)
					storeCartItems(cartData)

					const currency = getCartMetaRowFromResponse(cartData).currency
					updateCurrencyAndBillingInfo(currency)
					$eventBus.$emit(MODIFIED_CART_CURRENCY)
				})
			} else {
				$eventBus.$emit(MODIFIED_CART_CURRENCY)
			}
		},

		//Make sure to switch the currencyStore AFTER calling this method, otherwise no change will be detected
		$updateSeatSelection: async (holdToken, productSeatSelection) => {
			if (cartId.value) {
				$apiHelper.$showLoader()
				const { data, pending, status } = await $cartApiService.$updateSeatSelection(
					cartId.value,
					holdToken,
					productSeatSelection
				)

				$apiHelper.$handleResponse(pending, status, data, (response) => {
					const cartData = response.cart
					storeCartMeta(cartData)
					storeCartItems(cartData)
					cartStore.setReservationHoldToken(holdToken)
					confetti()
				})
			}
		},

		$clearCart: async () => {
			if (cartId.value) {
				$apiHelper.$showLoader()
				const { data, pending, status } = await $cartApiService.$clearCart(cartId.value)

				$apiHelper.$handleResponse(pending, status, data, () => {
					if (runtimeConfig.public.IS_KIOSK_APP) {
						resetCartDataToDefaults()
					} else {
						cartStore.resetCartMetaAndItems()
					}
					$eventBus.$emit(CART_CLEARED)
				})
			} else if (runtimeConfig.public.IS_KIOSK_APP) {
				//Allow kiosk uses to use clear cart button to clear checkout form
				await resetCartDataToDefaults()
				$eventBus.$emit(CART_CLEARED)
			}
		},

		$resetStoredCartData: resetCartDataToDefaults,
	})
})
