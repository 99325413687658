export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.provide("utilities", {
		$handleImportModuleError: (errorMsg) => {
			const importModuleErrors = ["Failed to fetch dynamically imported module", "Failed to load module script"]

			const containsError = importModuleErrors.some((msg) => errorMsg.includes(msg))

			if (containsError) {
				const cookie = useCookie("lastRefreshTime", { maxAge: 3600 })
				const lastRefreshTime = cookie.value
				const expirationTime = 5 * 60 * 1000 // 5 minutes
				const now = new Date().getTime()
				const isTimePassed = lastRefreshTime && now - parseInt(lastRefreshTime) > expirationTime

				if (!lastRefreshTime || isTimePassed) {
					cookie.value = now
					window.location.reload()
					return false
				}
			}

			return true
		},

		$isMobileDevice() {
			return window.matchMedia("only screen and (max-width: 760px)").matches
		},
	})
})
