import currencies from "~/assets/static/currencies.js"
import { useCurrencyStore } from "~/stores/CurrencyStore"

export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()
	const defaultCurrencyCode = runtimeConfig.public.CURRENCIES.DEFAULT
	const supportedCurrencies = runtimeConfig.public.CURRENCIES.SUPPORTED_CURRENCIES
	const supportedCurrenciesFallbackCountry = runtimeConfig.public.CURRENCIES.FALLBACK_CURRENCY_COUNTRY_CODE
	const currencyStore = useCurrencyStore()
	const gtm = useGtm()

	//TODO: add the countryIso3 to the currencies.js file as support for that country's currency is added

	const setCurrencyStoreToDefault = () => {
		const defaultCurrency = getCurrencyByCode(defaultCurrencyCode)
		currencyStore.switchCurrency(defaultCurrency)
	}

	const getCurrencyByCode = (code) => {
		code = code && code.toUpperCase()
		const currency = currencies.data.find((c) => c.code === code)
		return transformCurrencyObject(currency)
	}

	const transformCurrencyObject = (currency) => {
		return {
			symbol: currency.symbolNative,
			decimalDigits: currency.decimalDigits,
			rounding: currency.rounding,
			code: currency.code,
			namePlural: currency.namePlural,
			flag: currency.flag,
			countryIso3: currency.countryIso3,
		}
	}

	nuxtApp.provide("currency", {
		$init: () => {
			if (!currencyStore.currency) {
				setCurrencyStoreToDefault()
			} else if (gtm?.options?.enabled) {
				// @ts-ignore
				gtm?.dataLayer().push({ currency_code: defaultCurrencyCode })
			}
		},

		$getSupportedCurrencies: () => {
			//Only supporting CAD and USD for now
			let currencies = []
			for (const curr of supportedCurrencies) {
				currencies.push(getCurrencyByCode(curr))
			}
			return currencies
		},

		$getCurrencyByCode: getCurrencyByCode,

		$getCurrencyByCountryIso3: (iso3) => {
			iso3 = iso3 && iso3.toUpperCase()

			const currencyFound = currencies.data.find((c) => c.countryIso3 === iso3)

			let curr
			if (currencyFound && supportedCurrencies.includes(currencyFound.code)) {
				//Just because a currency was found in the dataset, does not mean it is supported by the site
				curr = currencyFound
			} else {
				curr = currencies.data.find((c) => c.countryIso3 === supportedCurrenciesFallbackCountry)
			}

			return transformCurrencyObject(curr)
		},

		$setStoreToDefaultCurrency: setCurrencyStoreToDefault,
	})
})
