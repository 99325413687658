<template>
	<ModalGrayModal
		:title="$t('cartTimeoutTitle')"
		container-class="max-w-sm cartExpiredPopupComponent"
		ref="modal">
		<div class="bg-gray-100 mt-3 py-3 px-6">
			<p>{{ $t("cartTimeoutBody") }}</p>
		</div>

		<div class="flex justify-center gap-8 py-4">
			<ButtonModalClose
				:label="$t('close')"
				@click="closeModal" />
		</div>
	</ModalGrayModal>
</template>

<script setup>
	import { useNuxtApp } from "#app"
	import { onMounted, onUnmounted } from "vue"
	import { SHOW_CART_TIMEOUT_EXPIRED } from "assets/events/cartTimeout"
	import { useRefsStore } from "~/stores/RefsStore"

	const { $eventBus } = useNuxtApp()
	const refsStore = useRefsStore()
	const modal = ref(null)

	onMounted(() => {
		refsStore.setCartTimeoutExpiredPopupRef(modal.value)

		$eventBus.$on(SHOW_CART_TIMEOUT_EXPIRED, () => {
			openModal()
		})
	})

	onUnmounted(() => {
		$eventBus.$off(SHOW_CART_TIMEOUT_EXPIRED)
	})

	const openModal = () => refsStore.cartTimeoutExpiredPopupRef.openModal()

	const closeModal = () => refsStore.cartTimeoutExpiredPopupRef.closeModal()
</script>
