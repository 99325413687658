<template>
	<div
		class="h-16 sm:w-60 flex items-center"
		:class="{ 'cursor-pointer': !showRestrictedCheckoutPage }"
		@click="goToHome">
		<img
			class="hidden sm:block object-contain logoComponent"
			:alt="runtimeConfig.public.EVENT_NAME + ' logo'"
			:src="runtimeConfig.public.DEFAULT_LOGO" />
		<img
			class="block sm:hidden max-h-full max-w-full mt-2 object-contain"
			:alt="runtimeConfig.public.EVENT_NAME + ' logo'"
			:src="runtimeConfig.public.DEFAULT_LOGO_CONDENSED" />
	</div>
</template>

<script setup>
	import { navigateTo } from "#app"
	import { useSettingsStore } from "~/stores/SettingsStore"
	import { storeToRefs } from "pinia"

	const runtimeConfig = useRuntimeConfig()
	const settingsStore = useSettingsStore()
	const { showRestrictedCheckoutPage } = storeToRefs(settingsStore)

	const goToHome = () => {
		if (!showRestrictedCheckoutPage.value) {
			navigateTo({
				path: "/",
			})
		}
	}
</script>

<style scoped></style>
