import CartItem from "~/models/CartItem"

export const useCartStore = defineStore("cartStore", () => {
	//Use info is store in the cart to allow for re-population of the form if the user navigates away
	//Shipping Information
	const shippingFirstName = ref("")
	const shippingLastName = ref("")
	const shippingEmail = ref("")
	const shippingEmailConfirm = ref("")
	const shippingPhone = ref("")
	const shippingAddress1 = ref("")
	const shippingAddress2 = ref("")
	const shippingCountry = ref("")
	const shippingState = ref("")
	const shippingCity = ref("")
	const shippingZipcode = ref("")
	const newsAndUpdates = ref(false)

	//Billing Information
	const billingFirstName = ref("")
	const billingLastName = ref("")
	const billingAddress1 = ref("")
	const billingAddress2 = ref("")
	const billingCountry = ref("")
	const billingState = ref("")
	const billingCity = ref("")
	const billingZipcode = ref("")
	const billingAndShippingAreSame = ref(false)

	//Product Information
	const appliedPromocode = ref(null)
	const discountedAmount = ref(0)

	const expiresAt = ref() //date object
	const hasShownExpiresWarning = ref(false)

	const cartId = ref("")
	const items = ref(new Map<string, CartItem>())
	const subtotal = ref(0)
	const total = ref(0)
	const fees = ref({})
	const refundableBookingCost = ref(0)
	const isRefundableBookingSelected = ref(false)
	let initInstallments: Array<object> = []
	const installments = ref(initInstallments)
	const exchangeRate = ref(0)
	const orderId = ref("")
	const reservationHoldToken = ref("")
	const currency = ref("")

	const setShippingFirstName = (value = "") => {
		shippingFirstName.value = value
		if (billingAndShippingAreSame.value) {
			billingFirstName.value = value
		}
	}
	const setShippingLastName = (value = "") => {
		shippingLastName.value = value
		if (billingAndShippingAreSame.value) {
			billingLastName.value = value
		}
	}
	const setShippingEmail = (value = "") => (shippingEmail.value = value)
	const setShippingEmailConfirm = (value = "") => (shippingEmailConfirm.value = value)

	const areEmailsTheSame = () => {
		return shippingEmail.value === shippingEmailConfirm.value
	}

	const setShippingPhone = (value = "") => (shippingPhone.value = value)

	const setShippingAddress1 = (value = "") => {
		shippingAddress1.value = value
		if (billingAndShippingAreSame.value) {
			billingAddress1.value = value
		}
	}
	const setShippingAddress2 = (value = "") => {
		shippingAddress2.value = value
		if (billingAndShippingAreSame.value) {
			billingAddress2.value = value
		}
	}
	const setShippingCountry = (value = "") => {
		shippingCountry.value = value
		if (billingAndShippingAreSame.value) {
			billingCountry.value = value
		}
	}
	const setShippingState = (value = "") => {
		shippingState.value = value
		if (billingAndShippingAreSame.value) {
			billingState.value = value
		}
	}
	const setShippingCity = (value = "") => {
		shippingCity.value = value
		if (billingAndShippingAreSame.value) {
			billingCity.value = value
		}
	}
	const setShippingZipcode = (value = "") => {
		shippingZipcode.value = value
		if (billingAndShippingAreSame.value) {
			billingZipcode.value = value
		}
	}
	const setNewsAndUpdates = (value = false) => (newsAndUpdates.value = value)

	const setBillingFirstName = (value = "") => (billingFirstName.value = value)
	const setBillingLastName = (value = "") => (billingLastName.value = value)
	const setBillingAddress1 = (value = "") => (billingAddress1.value = value)
	const setBillingAddress2 = (value = "") => (billingAddress2.value = value)
	const setBillingCountry = (value = "") => (billingCountry.value = value)
	const setBillingState = (value = "") => (billingState.value = value)
	const setBillingCity = (value = "") => (billingCity.value = value)
	const setBillingZipcode = (value = "") => (billingZipcode.value = value)
	const setBillingAndShippingAreSame = (value = false) => (billingAndShippingAreSame.value = value)

	const setAppliedPromocode = (value = null) => (appliedPromocode.value = value)

	const setDiscountedAmount = (value = 0) => (discountedAmount.value = value)

	const setExpiresAt = (value: Date) => {
		expiresAt.value = value
	}

	const setHasShownExpiresWarning = (value: boolean) => {
		hasShownExpiresWarning.value = value
	}

	const setCartId = (value = "") => {
		cartId.value = value
	}

	const getItem = (productId: string) => {
		return items.value.get(productId)
	}

	const addItem = (item: CartItem) => {
		items.value.set(item.id, item)
	}

	//Remove a product from the cart items map
	const removeItem = (productId: string) => {
		const item = items.value.get(productId)
		if (item) {
			items.value.delete(productId)
		}
		return item
	}

	const getNumProductsByType = (productTypeId: String) => {
		let count = 0
		items.value.forEach((cartItem: CartItem) => {
			if (cartItem.productType === productTypeId) {
				count += cartItem.quantity
			}
		})
		return count
	}

	const getTotalItemsCount = () => {
		let count = 0
		items.value.forEach((cartItem: CartItem) => {
			//Checks isRemovable to exclude things like 'processing_fees' from the count
			if (cartItem.isRemovable === true) {
				count += cartItem.quantity
			}
		})
		return count
	}

	const setSubTotal = (value: number) => {
		subtotal.value = value
	}

	const setTotal = (value: number) => {
		total.value = value
	}

	const setFees = (value: object) => {
		fees.value = value
	}

	const setRefundableBookingCost = (value: number) => {
		refundableBookingCost.value = value
	}

	const setIsRefundableBookingSelected = (value: boolean) => {
		isRefundableBookingSelected.value = value
	}

	const setInstallments = (value: object[]) => {
		installments.value = value
	}

	const setExchangeRate = (value: number) => {
		exchangeRate.value = value
	}

	const setOrderId = (value: string) => {
		orderId.value = value
	}

	const setReservationHoldToken = (value: string) => {
		reservationHoldToken.value = value
	}

	const setCurrency = (value: string) => {
		currency.value = value
	}

	const setEntireCart = (cartStore: any) => {
		shippingFirstName.value = cartStore.shippingFirstName
		shippingLastName.value = cartStore.shippingLastName
		shippingEmail.value = cartStore.shippingEmail
		shippingEmailConfirm.value = cartStore.shippingEmailConfirm
		shippingPhone.value = cartStore.shippingPhone
		shippingAddress1.value = cartStore.shippingAddress1
		shippingAddress2.value = cartStore.shippingAddress2
		shippingCountry.value = cartStore.shippingCountry
		shippingState.value = cartStore.shippingState
		shippingCity.value = cartStore.shippingCity
		shippingZipcode.value = cartStore.shippingZipcode
		newsAndUpdates.value = cartStore.newsAndUpdates
		billingFirstName.value = cartStore.billingFirstName
		billingLastName.value = cartStore.billingLastName
		billingAddress1.value = cartStore.billingAddress1
		billingAddress2.value = cartStore.billingAddress2
		billingCountry.value = cartStore.billingCountry
		billingState.value = cartStore.billingState
		billingCity.value = cartStore.billingCity
		billingZipcode.value = cartStore.billingZipcode
		billingAndShippingAreSame.value = cartStore.billingAndShippingAreSame
		appliedPromocode.value = cartStore.appliedPromocode
		expiresAt.value = cartStore.expiresAt
		hasShownExpiresWarning.value = cartStore.hasShownExpiresWarning
		cartId.value = cartStore.cartId
		items.value = cartStore.items
		subtotal.value = cartStore.subtotal
		total.value = cartStore.total
		fees.value = cartStore.fees
		refundableBookingCost.value = cartStore.refundableBookingCost
		isRefundableBookingSelected.value = cartStore.isRefundableBookingSelected
		installments.value = cartStore.installments
		orderId.value = cartStore.orderId
		appliedPromocode.value = cartStore.appliedPromocode
		discountedAmount.value = cartStore.discountedAmount
		exchangeRate.value = cartStore.exchangeRate
		reservationHoldToken.value = cartStore.reservationHoldToken
		currency.value = cartStore.currency
	}

	const resetUserInformation = () => {
		shippingFirstName.value = ""
		shippingLastName.value = ""
		shippingEmail.value = ""
		shippingEmailConfirm.value = ""
		shippingPhone.value = ""
		shippingAddress1.value = ""
		shippingAddress2.value = ""
		shippingCountry.value = ""
		shippingState.value = ""
		shippingCity.value = ""
		shippingZipcode.value = ""
		newsAndUpdates.value = false

		//Billing Information
		billingFirstName.value = ""
		billingLastName.value = ""
		billingAddress1.value = ""
		billingAddress2.value = ""
		billingCountry.value = ""
		billingState.value = ""
		billingCity.value = ""
		billingZipcode.value = ""
		billingAndShippingAreSame.value = false
	}

	const resetCartMetaAndItems = () => {
		cartId.value = ""
		expiresAt.value = null
		hasShownExpiresWarning.value = false
		subtotal.value = 0
		total.value = 0
		fees.value = {}
		refundableBookingCost.value = 0
		isRefundableBookingSelected.value = false
		installments.value = []
		orderId.value = ""
		appliedPromocode.value = null
		discountedAmount.value = 0
		exchangeRate.value = 0
		reservationHoldToken.value = ""
		currency.value = ""
		resetCartItems()
	}

	const resetCartItems = () => {
		items.value = new Map<string, CartItem>()
	}

	//Use the $resetStoredCartData method in cartService unless otherwise intended
	const $reset = () => {
		resetUserInformation()
		resetCartMetaAndItems()
	}

	return {
		shippingFirstName,
		setShippingFirstName,
		shippingLastName,
		setShippingLastName,
		shippingEmail,
		setShippingEmail,
		shippingEmailConfirm,
		setShippingEmailConfirm,
		areEmailsTheSame,
		shippingPhone,
		setShippingPhone,
		shippingAddress1,
		setShippingAddress1,
		shippingAddress2,
		setShippingAddress2,
		shippingCountry,
		setShippingCountry,
		shippingState,
		setShippingState,
		shippingCity,
		setShippingCity,
		shippingZipcode,
		setShippingZipcode,
		newsAndUpdates,
		setNewsAndUpdates,
		billingFirstName,
		setBillingFirstName,
		billingLastName,
		setBillingLastName,
		billingAddress1,
		setBillingAddress1,
		billingAddress2,
		setBillingAddress2,
		billingCountry,
		setBillingCountry,
		billingState,
		setBillingState,
		billingCity,
		setBillingCity,
		billingZipcode,
		setBillingZipcode,
		billingAndShippingAreSame,
		setBillingAndShippingAreSame,
		appliedPromocode,
		setAppliedPromocode,
		discountedAmount,
		setDiscountedAmount,
		expiresAt,
		setExpiresAt,
		hasShownExpiresWarning,
		setHasShownExpiresWarning,
		resetUserInformation,
		cartId,
		setCartId,
		items,
		getItem,
		addItem,
		removeItem,
		getNumProductsByType,
		getTotalItemsCount,
		subtotal,
		setSubTotal,
		total,
		setTotal,
		fees,
		setFees,
		refundableBookingCost,
		setRefundableBookingCost,
		isRefundableBookingSelected,
		setIsRefundableBookingSelected,
		installments,
		setInstallments,
		exchangeRate,
		setExchangeRate,
		orderId,
		setOrderId,
		reservationHoldToken,
		setReservationHoldToken,
		currency,
		setCurrency,
		setEntireCart,
		resetCartMetaAndItems,
		resetCartItems,
		$reset,
	}
})
