<template>
	<ErrorsErrorBoundary>
		<NuxtLayout>
			<ProductSeatSelector
				v-if="!!seatsIOConfig"
				:seats-io-workspace-key="seatsIOConfig.PUBLIC_WORKSPACE_KEY"
				:seats-io-event="seatsIOConfig.EVENT_ID" />

			<ErrorsErrorPopup />
			<ModalLoadingPopup />
			<CheckoutConfirmationPopup />

			<NuxtPage />

			<CookiesNoticePopup />
			<CartTimeoutExpiredPopup />
			<CartTimeoutWarningPopup />
		</NuxtLayout>
	</ErrorsErrorBoundary>
</template>

<script setup>
	//Note that all pages will use the default layout. Each page may utilize an additional layout nested within the default layout.
	//The Showing/Hiding logic of the Header and Footer is contained within the Header/Footer components.
	import { useNuxtApp } from "#app"
	const {
		$persistentStores,
		$cartService,
		$currency,
		$cartTimeout,
		$messages,
		$productsService,
		$login,
		$seatSelector,
	} = useNuxtApp()

	const { t } = useI18n()
	$messages.$init(t)
	$currency.$init()

	const appConfig = useAppConfig()
	if (!appConfig.showMaintenancePage) {
		$persistentStores.$init()

		$productsService.$getAppSettings()

		onMounted(() => {
			//Make sure $cartTimeout.$init() is called before persistentStores.$refresh(), so that the watcher can detect the change in expiresAt
			$cartTimeout.$init()
			$cartService.$init()
			$persistentStores.$refresh()
			$login.$init()
			$seatSelector.$init()

			refreshStoresOnTabChange()
		})

		const refreshStoresOnTabChange = () => {
			//Reload the stores if the tab changes to prevent users from opening multiple tabs to browse the site.
			document.addEventListener("visibilitychange", () => {
				if (document.visibilityState === "visible") {
					$persistentStores.$refresh()
				}
			})
		}

		//The preload is in place to prevent the "error dynamically loading" javascript error
		//This is needed since the confirmation page is dynamically navigated to from the checkout page
		preloadRouteComponents(["/", "/order/confirmation"])
	}

	// Load Seats Reservation Popup as a global component. Opted for this design so that the use can view/edit their selection from any
	// page (no need to redirect back to lodging). However, this architecture only allows for one reservation chart sitewide instead of per product.
	const runtimeConfig = useRuntimeConfig()
	const seatsIOConfig = runtimeConfig.public.SEATS_IO
</script>
