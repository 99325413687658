//Cart Related Events
export const CART_CLEARED = "cart:cart_cleared"

export const SHOW_SEAT_SELECTOR_POPUP = "seats_io:show_seat_selector_popup"
export const HIDE_SEAT_SELECTOR_POPUP = "seats_io:hide_seat_selector_popup"

export const SHOW_SEAT_PREVIEW_POPUP = "seats_io:show_seat_preview_popup"
export const HIDE_SEAT_PREVIEW_POPUP = "seats_io:hide_seat_preview_popup"

export const MODIFIED_RESERVED_ITEM = "seats_io:modified_item"

export const MODIFIED_CART_CURRENCY = "cart:changed_currency_via_country"
