<template>
	<ModalBase
		ref="baseModal"
		:container-class="containerClass"
		:click-outside-enabled="clickOutsideEnabled">
		<div class="min-w-[20em] rounded-lg text-black bg-gray-100">
			<div class="h-[7vh] md:h-16 border-b flex justify-between items-center shadow-md bg-white rounded-t-xl px-6">
				<div>
					<p class="font-serif text-xl text-secondary/80 break-words">{{ title }}</p>
				</div>
				<div
					v-if="!hideCloseBtn"
					class="text-gray-400">
					<IconsFontAwesome
						icon="fa-solid fa-xmark"
						class="hover:cursor-pointer"
						@click="closeModal" />
				</div>
			</div>

			<slot></slot>
		</div>
	</ModalBase>
</template>

<script setup>
	defineProps({
		title: {
			type: String,
			required: true,
		},
		containerClass: String,
		clickOutsideEnabled: {
			type: Boolean,
			default: true,
		},
		hideCloseBtn: {
			type: Boolean,
			default: false,
		},
	})

	const baseModal = ref(null)

	const openModal = () => {
		baseModal.value.openModal()
	}

	const closeModal = () => {
		baseModal.value.closeModal()
	}

	defineExpose({
		openModal,
		closeModal,
	})
</script>

<style scoped></style>
