export const useCurrencyStore = defineStore("currencyStore", () => {
	const currency = ref()
	const gtm = useGtm()

	interface Currency {
		symbol: string
		decimalDigits: number
		rounding: number
		code: string
		namePlural: string
		flag: string
		countryIso3: string
	}

	const switchCurrency = (curr: Currency) => {
		currency.value = curr
		trackCurrencyForAnalytics(curr.code)
	}

	const setCurrencyStore = (currencyStore: any) => {
		currency.value = currencyStore.currency
		trackCurrencyForAnalytics(currencyStore.currency.code)
	}

	const trackCurrencyForAnalytics = (code: String) => {
		if (gtm?.options?.enabled) {
			// @ts-ignore
			gtm?.dataLayer().push({ currency_code: code })
		}
	}

	return { currency, switchCurrency, setCurrencyStore }
})
