import { useSettingsStore } from "~/stores/SettingsStore"
import { HIDE_SEAT_SELECTOR_POPUP, MODIFIED_RESERVED_ITEM, SHOW_SEAT_SELECTOR_POPUP } from "assets/events/cart.js"
import { useCartStore } from "~/stores/CartStore"
import { storeToRefs } from "pinia"
import { CART_CLEARED } from "assets/events/cart.js"
import * as Sentry from "@sentry/browser"

export default defineNuxtPlugin((nuxtApp) => {
	const { $eventBus, $cartService } = useNuxtApp()
	const settingsStore = useSettingsStore()
	const cartStore = useCartStore()
	const { items } = storeToRefs(cartStore)

	const init = () => {
		$eventBus.$on(CART_CLEARED, cartClearedHandler)
		$eventBus.$on(MODIFIED_RESERVED_ITEM, changedCartItemQuantity)

		nextTick(pageRefreshHandler)

		document.addEventListener("visibilitychange", () => {
			if (document.visibilityState === "visible") {
				pageRefreshHandler()
			}
		})
	}

	const cartClearedHandler = () => {
		sessionStorage.removeItem("seatsio")
		$eventBus.$emit(HIDE_SEAT_SELECTOR_POPUP)
	}

	const pageRefreshHandler = () => {
		//Re-opens the seatSelector if page refreshed or the url changed while the user was selecting a seat
		const itemsWithReservation = getReservableCartItems()
		if (itemsWithReservation.length <= 0) {
			settingsStore.setIsSelectingSeat(false)
		} else if (settingsStore.isSelectingSeat) {
			editSelection()
		}
	}

	const changedCartItemQuantity = (options) => {
		const { productId } = options
		const modifiedProduct = cartStore.items.get(productId)
		const shouldShowSeatSelector = !!modifiedProduct.reservationSection
		if (!shouldShowSeatSelector) {
			return
		}

		const itemsWithReservation = getReservableCartItems()
		showSelectionPopup(itemsWithReservation, { editingCategory: modifiedProduct.reservationSection })
	}

	const editSelection = () => {
		const itemsWithReservation = getReservableCartItems()
		showSelectionPopup(itemsWithReservation)
	}

	const showSelectionPopup = (itemsWithReservation, eventOptions) => {
		//Build the seatsio.maxSelectedObjects model to limit user selection (https://docs.seats.io/docs/renderer/config-maxselectedobjects)
		const maxSelectedObjects = itemsWithReservation.map((item) => {
			return { category: item.reservationSection, quantity: item.quantity }
		})
		$eventBus.$emit(SHOW_SEAT_SELECTOR_POPUP, { maxSelectedObjects: maxSelectedObjects, ...eventOptions })
	}

	const getDeltaInDesiredSelection = (currentSelection) => {
		//Compare the currently SelectedSeats against reservedProducts quantity, could be more or less than needed
		const itemsWithReservation = getReservableCartItems()
		const productSeatSelection = buildProductToSeatMap(itemsWithReservation, currentSelection)
		let deltaInDesiredSelected = {}

		//Builds Map: {productId: cartItemQty - selectedItemQty}
		itemsWithReservation.forEach((item) => {
			deltaInDesiredSelected[item.id] = productSeatSelection[item.id]
				? item.quantity - productSeatSelection[item.id].length
				: item.quantity
		})

		return deltaInDesiredSelected
	}

	const updateReservedSeatSelection = (holdToken, currentSelection) => {
		//Will need to match all cart product ids with the seat selections
		const itemsWithReservation = getReservableCartItems()
		const productSeatSelection = buildProductToSeatMap(itemsWithReservation, currentSelection)

		$cartService.$updateSeatSelection(holdToken, productSeatSelection)
	}

	const getReservableCartItems = () => {
		const allCartItems = [...cartStore.items.values()]

		//Any cartItem with 'reservationSection' filled should be displayed/selectable in seatsIO chart
		return allCartItems.filter((item) => !!item.reservationSection)
	}

	const parseSeatReservationSection = (seatLabel) => {
		const indexOfSeatNumber = seatLabel.lastIndexOf("-")
		return seatLabel.substring(0, indexOfSeatNumber)
	}

	const buildProductToSeatMap = (reservedProducts, currentSelection) => {
		const productSeatSelection = {}
		reservedProducts.forEach((product) => {
			productSeatSelection[product.id] = []
		})

		currentSelection.forEach((seatLabel) => {
			const seatReservationSection = parseSeatReservationSection(seatLabel)
			const productToReserve = reservedProducts.find((item) => item.reservationSection === seatReservationSection)

			if (productToReserve) {
				const productId = productToReserve.id
				productSeatSelection[productId].push(seatLabel)
			} else {
				console.log(JSON.stringify(reservedProducts)) //Log here so sentry can capture the message
				Sentry.captureMessage(`A product was added to cart but has no reservationSection`, "error")
			}
		})

		return productSeatSelection
	}

	nuxtApp.provide("seatSelector", {
		$init: init,
		$pageRefreshCheck: pageRefreshHandler,
		$getReservableCartItems: getReservableCartItems,
		$editSelection: editSelection,
		$getDeltaInDesiredSelection: getDeltaInDesiredSelection,
		$updateCartSeatSelection: updateReservedSeatSelection,
	})
})
